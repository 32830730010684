<template>
	<div>
		<div class="page-title">
			<span style="font-size: 20px;">数据统计</span>
		</div>
		<div class="mt-2 mb-2 p-3">
			<div class="row clearfix">
				<div class="col-md-3 column">
					<el-card shadow="always">
						<div slot="header" class="clearfix ">
							<span>今日新增用户</span>

							<el-tag style="float: right;" type="success">用户</el-tag>
						</div>
						<div class="middle">
							<div class="num1-box">
								{{cardData.today_user_count}}
							</div>
						</div>
						<div class="bottom clearfix">
							<div class="d-flex align-items-center justify-content-between">
								<div class="left">总用户数量</div>
								<div class="right">{{cardData.total_user_count}}</div>
							</div>
						</div>
					</el-card>
				</div>
				<div class="col-md-3 column">
					<el-card shadow="always">
						<div slot="header" class="clearfix">
							<span>今日新增商家</span>
							<el-tag style="float: right;" type="success">商家</el-tag>
						</div>
						<div class="middle">
							<div class="num1-box">
								{{cardData.today_merchant_count}}
							</div>
						</div>
						<div class="bottom clearfix">
							<div class="d-flex align-items-center justify-content-between">
								<div class="left">总商家数量</div>
								<div class="right">{{cardData.total_merchant_count}}</div>
							</div>
						</div>
					</el-card>
				</div>
				<div class="col-md-3 column">
					<el-card shadow="always">
						<div slot="header" class="clearfix">
							<span>今日新增活动</span>
							<el-tag style="float: right;" type="success">活动</el-tag>
						</div>

						<div class="middle">
							<div class="num1-box">
								{{cardData.today_coupon_count}}
							</div>
						</div>

						<div class="bottom clearfix">
							<div class="d-flex align-items-center justify-content-between">
								<div class="left">总活动数量</div>
								<div class="right">{{cardData.total_coupon_count}}</div>
							</div>
						</div>
					</el-card>
				</div>
				<div class="col-md-3 column">
					<el-card shadow="always">
						<div slot="header" class="clearfix">
							<span>今日领取数量</span>
							<el-tag style="float: right;" type="success">领取</el-tag>
						</div>

						<div class="middle">
							<div class="num1-box">
								{{cardData.today_user_coupon_count}}
							</div>
						</div>

						<div class="bottom clearfix">
							<div class="d-flex align-items-center justify-content-between">
								<div class="left">总领取数量</div>
								<div class="right">{{cardData.total_user_coupon_count}}</div>
							</div>
						</div>
					</el-card>
				</div>
			</div>
		</div>

		<div class="bg-white m-2 p-2">
			<div class="row clearfix">
				<div class="col-md-3 column mb-2">
					<div class="row clearfix">
						<div class="col-md-6 column mb-2" @click="navigation('user/index')">
							<el-card shadow="hover">
								<div class="d-flex justify-content-between align-items-center flex-column">
									<i style="font-size:16px;color: rgb(105, 192, 255);" class="el-icon-user m-2"></i>
									<span class="h8">用户管理</span>
								</div>
							</el-card>
						</div>

						<div class="col-md-6 column mb-2" @click="navigation('user.merchant/index')">
							<el-card shadow="hover">
								<div class="d-flex justify-content-between align-items-center flex-column">
									<i style="font-size:16px;color: rgb(255, 156, 110);"
										class="el-icon-chat-round m-2"></i>
									<span class="h8">商家管理</span>
								</div>
							</el-card>
						</div>
					</div>
				</div>

				<div class="col-md-3 column mb-2">
					<div class="row clearfix">
						<div class="col-md-6 column mb-2" @click="navigation('coupon/index')">
							<el-card shadow="hover">
								<div class="d-flex justify-content-between align-items-center flex-column">
									<i style="font-size:16px;color: rgb(149, 222, 100);"
										class="el-icon-tickets m-2"></i>
									<span class="h8">优惠券管理</span>
								</div>
							</el-card>
						</div>

						<div class="col-md-6 column mb-2" @click="navigation('coupon/record')">
							<el-card shadow="hover">
								<div class="d-flex justify-content-between align-items-center flex-column">
									<i style="font-size:16px;color: rgb(179, 127, 235);"
										class="el-icon-tickets m-2"></i>
									<span class="h8">领取记录</span>
								</div>
							</el-card>
						</div>
					</div>
				</div>

				<div class="col-md-3 column mb-2">
					<div class="row clearfix">
						<div class="col-md-6 column mb-2" @click="navigation('report/index')">
							<el-card shadow="hover">
								<div class="d-flex justify-content-between align-items-center flex-column">
									<i style="font-size:16px;color: rgb(255, 214, 102);"
										class="el-icon-s-order m-2"></i>
									<span class="h8">举报管理</span>
								</div>
							</el-card>
						</div>

						<div class="col-md-6 column mb-2" @click="navigation('content.material/index')">
							<el-card shadow="hover">
								<div class="d-flex justify-content-between align-items-center flex-column">
									<i style="font-size:16px;color: rgb(92, 219, 211);"
										class="el-icon-document m-2"></i>
									<span class="h8">素材管理</span>
								</div>
							</el-card>
						</div>
					</div>
				</div>

				<div class="col-md-3 column mb-2">
					<div class="row clearfix">
						<div class="col-md-6 column mb-2" @click="navigation('wxapp/setting')">
							<el-card shadow="hover">
								<div class="d-flex justify-content-between align-items-center flex-column">
									<i style="font-size:16px;color: rgb(255, 133, 192);" class="el-icon-menu m-2"></i>
									<span class="h8">小程序</span>
								</div>
							</el-card>
						</div>

						<div class="col-md-6 column mb-2" @click="navigation('setting/setting')">
							<el-card shadow="hover">
								<div class="d-flex justify-content-between align-items-center flex-column">
									<i style="font-size:16px;color: rgb(255, 133, 192);"
										class="el-icon-setting m-2"></i>
									<span class="h8">设置</span>
								</div>
							</el-card>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="bg-white mt-2 mb-2">
			<div class="row clearfix">
				<div class="col-md-12 column mb-2">
					<!-- 折线图 -->
					<el-card shadow="always">
						<div slot="header" class="d-flex align-items-center justify-content-between">
							<span>订单</span>
							<el-tag type="success">标签二</el-tag>
						</div>
						<div id="line" style="width: auto;height:450px;"></div>
					</el-card>
				</div>
			</div>
		</div>
		<div class="bg-white mt-2 mb-2">
			<!-- 柱状图 -->
			<div class="row clearfix">
				<div class="col-md-8 column mb-2">
					<el-card shadow="always">
						<div slot="header" class="d-flex align-items-center justify-content-between">
							<span>用户</span>
							<el-tag type="success">标签二</el-tag>
						</div>
						<div id="bar" style="width: 100%;height:450px;"></div>
					</el-card>
				</div>


				<!-- 饼图 -->
				<div class="col-md-4 column mb-2">
					<el-card shadow="always">
						<div slot="header" class="d-flex align-items-center justify-content-between">
							<span>购买用户统计</span>
							<el-tag type="success">标签二</el-tag>
						</div>
						<div id="pie" style="width: 100%;height:450px;"></div>
					</el-card>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import * as echarts from 'echarts';
	export default {
		inject: ['app', 'layout'],
		data() {
			return {
				cardData: {
					today_user_count: 0,
					total_user_count: 0,
					today_merchant_count: 0,
					total_merchant_count: 0,
					today_coupon_count: 0,
					total_coupon_count: 0,
					today_user_coupon_count: 0,
					total_user_coupon_count: 0
				}
			}
		},
		mounted() {
			this.getCardData()
			this.initCharts()
		},
		methods: {
			getCardData() {
				this.layout.showLoading()
				this.axios({
					token: true,
					params: {
						s: 'store/index/cardData'
					},
					method: 'get',
				}).then(res => {
					this.layout.hideLoading()
					this.cardData = res.data.data
				}).catch(err => {
					this.layout.hideLoading()
				})
			},
			navigation(name) {
				// 跳转后台首页
				this.$router.push({
					name: name
				})
			},
			initCharts() {
				// 初始化折线图
				this.initLine()
				//初始化柱状图
				this.initBar()
				//初始化饼图
				this.initPie()
			},

			// 初始化折线图
			initLine() {
				// 初始化
				let myChart = echarts.init(document.getElementById('line'));
				// 指定图表的配置项和数据
				let option = {
					title: {
						text: '订单量趋势'
					},
					tooltip: {
						trigger: 'axis'
					},
					legend: {
						data: ['邮件营销', '联盟广告', '视频广告', '直接访问', '搜索引擎']
					},
					grid: {
						left: '3%',
						right: '3%',
						bottom: '3%',
						containLabel: true
					},
					toolbox: {
						right: '3%',
						feature: {
							saveAsImage: {
								title: '导出视图'
							}, //下载保存为图片,

						}
					},
					xAxis: {
						type: 'category',
						boundaryGap: true,
						data: ['周一', '周二', '周三', '周四', '周五', '周六', '周日']
					},
					yAxis: {
						type: 'value'
					},
					series: [{
							name: '邮件营销',
							type: 'line',
							stack: '总量',
							data: [120, 132, 101, 134, 90, 230, 210]
						},
						{
							name: '联盟广告',
							type: 'line',
							stack: '总量',
							data: [220, 182, 191, 234, 290, 330, 310]
						},
						{
							name: '视频广告',
							type: 'line',
							stack: '总量',
							data: [150, 232, 201, 154, 190, 330, 410]
						},
						{
							name: '直接访问',
							type: 'line',
							stack: '总量',
							data: [320, 332, 301, 334, 390, 330, 320]
						},
						{
							name: '搜索引擎',
							type: 'line',
							stack: '总量',
							data: [820, 932, 901, 934, 1290, 1330, 1320]
						}
					]
				};
				// 使用刚指定的配置项和数据显示图表。
				myChart.setOption(option);
				setTimeout(() => {
					window.onresize = () => {
						myChart.resize();
					}
				}, 200)
			},

			// 初始化柱状图
			initBar() {
				// 初始化
				let barChart = echarts.init(document.getElementById('bar'));
				// 指定图表的配置项和数据
				let option = {
					tooltip: {
						trigger: 'axis',
						axisPointer: { // 坐标轴指示器，坐标轴触发有效
							type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
						}
					},
					grid: {
						left: '3%',
						right: '4%',
						bottom: '3%',
						containLabel: true
					},
					xAxis: [{
						type: 'category',
						data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
						axisTick: {
							alignWithLabel: true
						}
					}],
					yAxis: [{
						type: 'value'
					}],
					series: [{
						name: '直接访问',
						type: 'bar',
						barWidth: '60%',
						data: [10, 52, 200, 334, 390, 330, 220]
					}]
				};
				// 使用刚指定的配置项和数据显示图表。
				barChart.setOption(option);
				setTimeout(() => {
					window.onresize = () => {
						barChart.resize();
					}
				}, 200)
			},

			// 初始化饼图
			initPie() {
				// 初始化
				let pieChart = echarts.init(document.getElementById('pie'));
				// 指定图表的配置项和数据
				let option = {
					title: {
						text: '某站点用户访问来源',
						subtext: '纯属虚构',
						left: 'center'
					},
					tooltip: {
						trigger: 'item'
					},
					legend: {
						orient: 'vertical',
						left: 'left',
					},
					series: [{
						name: '访问来源',
						type: 'pie',
						radius: '50%',
						data: [{
								value: 1048,
								name: '搜索引擎'
							},
							{
								value: 735,
								name: '直接访问'
							},
							{
								value: 580,
								name: '邮件营销'
							},
							{
								value: 484,
								name: '联盟广告'
							},
							{
								value: 300,
								name: '视频广告'
							}
						],
						emphasis: {
							itemStyle: {
								shadowBlur: 10,
								shadowOffsetX: 0,
								shadowColor: 'rgba(0, 0, 0, 0.5)'
							}
						}
					}]
				};
				// 使用刚指定的配置项和数据显示图表。
				pieChart.setOption(option);
				setTimeout(() => {
					window.onresize = () => {
						pieChart.resize();
					}
				}, 200)
			}
		},
	}
</script>

<style scoped>
	.bg-white>>>.el-card__body {
		padding: 0.625rem 1.25rem
	}

	.middle {
		padding: 0.625rem 0rem;
	}

	.middle .num1-box {
		font-size: 25px;
		font-weight: bold;
	}

	.middle .num2-box {
		font-size: 15px;
		display: flex;
		align-items: center;
	}

	.middle .num2-box .value1 {}

	.middle .num2-box .value2 {}

	.bottom {
		padding: 0.5rem 0rem;
		font-size: 14px;
		border-top: 0.0625rem solid #EBEEF5;
	}
</style>
